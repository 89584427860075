<template>
  <el-dialog
    top="30px"
    append-to-body
    :visible.sync="currentShowFlag"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <div
      slot="title"
      class="detail-dialog__header"
    >
      {{ $t('config_affair.detailDialog') }}
    </div>
    <div>
      <simple-form
        ref="form"
        v-model="formModel"
        label-width="80px"
        :form-field="formField"
        :grid="{xs: 24, sm: 12, md: 8}"
        :view-flag="viewFlag"
      />
    </div>
    <div slot="footer">
      <el-button @click="handleDialogClose">
        {{ viewFlag ? $t('operation.close') : $t('operation.cancel') }}
      </el-button>
      <el-button
        v-if="!viewFlag"
        type="primary"
        @click="handleDataSubmit"
      >
        {{ $t('operation.submit') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
const BASEURL = {
  update: '/jlr/mt/affair/update',
  get: '/jlr/mt/affair/get',
  save: '/jlr/mt/affair/save'
}
export default {
  name: 'ConfigAffairDetail',
  props: {
    showFlag: { type: Boolean, default: false },
    viewFlag: { type: Boolean },
    detailData: { type: Object }
  },
  data () {
    return {
      formModel: {}
    }
  },
  inject: ['handleDataSearch'],
  computed: {
    currentShowFlag: {
      get () {
        return this.showFlag
      },
      set (val) {
        this.$emit('update:showFlag', val)
      }
    },
    formField () {
      return [
        {
          prop: 'name',
          type: 'Input',
          label: this.$t('config_affair.name'),
          component: { clearable: true, disabled: true },
          col: { xs: 12, sm: 12, md: 12 },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'code',
          type: 'Input',
          label: this.$t('config_affair.code'),
          component: { clearable: true, disabled: true },
          col: { xs: 12, sm: 12, md: 12 },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },

        {
          prop: 'bizStatus',
          type: 'Select',
          label: this.$t('config_affair.bizStatus'),
          col: { xs: 12, sm: 12, md: 12 },
          component: { clearable: true, optionList: this.$getDictList('data_status') },
          rules: { trigger: 'blur', message: this.$t('validate.notSelect'), required: true }
        },
        {
          prop: 'checkRule',
          type: 'Input',
          label: this.$t('config_affair.rule'),
          component: { clearable: true },
          col: { xs: 24, sm: 24, md: 24 },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'sort',
          type: 'Input',
          label: this.$t('config_affair.sort'),
          component: { clearable: true, disabled: true },
          col: { xs: 12, sm: 12, md: 12 },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        }
      ]
    }
  },
  methods: {
    handleDialogClose () {
      this.currentShowFlag = false
    },
    handleDataSubmit () {
      this.$refs.form.$refs.simpleForm.validate(status => {
        if (status) {
          const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
          const submitModel = this.$_.cloneDeep(this.formModel)
          this.$axios
            .post(this.detailData.id ? BASEURL.update : BASEURL.save, submitModel)
            .then(resp => {
              this.currentShowFlag = false
              this.$message({ type: 'success', message: this.$t('tip.saveSuccess') })
              this.handleDataSearch()
            })
            .finally(() => {
              loadingFlag.close()
            })
        }
      })
    },
    handleDialogOpen () {
      if (!this.detailData.id) return
      const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
      this.$axios
        .post(BASEURL.get, { id: this.detailData.id })
        .then(resp => {
          const respData = resp.data
          this.formModel = respData
        })
        .finally(() => {
          loadingFlag.close()
        })
    },

    handleDialogClosed () {
      this.$refs.form.resetFields()
      this.formModel = {}
    }
  }
}
</script>
